h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: 'Roboto Slab', serif;
  font-weight: 600;
}

.lead {
  font-weight: 400;
}

p {
  font-size: 1.1rem;
  line-height: 1.7rem;
}
