.form {
  background-color: $secondary;
  color: white;
  padding-bottom: 60px;

  a {
    // color: $secondary;
  }
  h3,
  h6 {
    text-align: center;
  }
  h3 {
    margin-bottom: 40px;
    span {
      background-color: $gray-100;
      color: $secondary;
    }
  }
  h6 {
    //margin-bottom: 40px;
    line-height: 2rem;
    font-weight: 300;
    letter-spacing: 0.04rem;
    margin-bottom: 0;
  }
  form {
    margin-bottom: 60px;
  }

  button:disabled {
    // background-color: #fff;
    // border: #fff;
  }
  .form-control {
    font-weight: 600;
    border-radius: 2px;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      height: 34px;
      opacity: 0.6;
    }
  }
}
