.hero {
  // background-color: $secondary;
  padding: 170px 0 400px 0;
  background-image: url('../../static/hero.webp');
  background-size: cover;
  background-position: center;
  color: white;
  position: relative;
  .overlay {
    background-color: rgba(darken($primary, 45%), 0.6);
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .content {
    //background-color: red;
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
  }
  .container {
    text-align: center;
    h1 {
      margin-bottom: 20px;
      font-size: 3rem;
    }
    .lead {
      margin-bottom: 50px;
      font-size: 1.54rem;
      b {
        font-weight: 600;
        color: $primary;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .hero {
    padding: 100px 0 400px 0;
    .container {
      h1 {
        font-weight: 600;
        margin-bottom: 20px;
        font-size: 2rem;
        line-height: 2.2rem;
      }
      .lead {
        margin-bottom: 30px;
        font-size: 1.3rem;
        line-height: 1.8rem;
      }
    }
  }
}
