html {
  position: relative;
  min-height: 100%;
}

body {
  margin-bottom: 60px;
  background-image: radial-gradient(rgba(30, 53, 71, 0.14) 1px, #fdfdfd 1px),
    radial-gradient(rgba(30, 53, 71, 0.14) 1px, #fdfdfd 1px);
  background-position: 0 0, 25px 25px;
  background-size: 16px 16px;
}

section {
  padding: 100px 0;
  h3 {
    margin-bottom: 80px;
    line-height: 2.4rem;
    font-weight: 400;
    text-align: center;
    span {
      background-color: $secondary;
      color: $white;
      padding: 4px 20px;
    }
  }
}

.btn-cta {
  padding: 12px 30px;
  letter-spacing: 0.02rem;
  font-size: 1.3rem;
  font-weight: 500;
  text-transform: uppercase;
}

.cta {
  background-color: $secondary;
  color: whitesmoke;
  padding: 60px 0;

  .cta-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      width: 70%;
      line-height: 2.8rem;
      font-weight: 300;
      span {
        color: $primary;
        font-weight: 400;
      }
    }
    .btn {
      font-size: 1.4rem;
      text-transform: uppercase;
    }
  }
}

@media screen and (max-width: 767px) {
  section {
    padding: 80px 0;
    h3 {
      margin-bottom: 50px;
      font-size: 1.6rem;
    }
  }

  .btn-cta {
    padding: 8px 24px;
    letter-spacing: 0.02rem;
    font-size: 1.2rem;
    font-weight: 500;
    text-transform: uppercase;
  }

  .cta {
    padding: 60px 0;

    .cta-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      h2 {
        width: 70%;
        line-height: 2.2rem;
        font-weight: 300;
        margin-bottom: 30px;
        font-size: 1.6rem;
        span {
          color: $primary;
          font-weight: 400;
        }
      }
      .btn {
        font-size: 1.2rem;
        text-transform: uppercase;
      }
    }
  }
}
