$color-whatsapp-green-1: #075e54;
$color-whatsapp-green-2: #128c7e;
$color-whatsapp-green-3: #25d366;
$color-bg-chat-whatsapp: #ece5dd;
.whatsapp-button {
  position: fixed;
  bottom: 26px;
  right: 26px;
  z-index: 99;

  .form {
    position: relative;
    padding: 24px 20px;
    background: $color-whatsapp-green-1;
    color: white;
    z-index: 99;
    width: 250px;
    border-radius: 6px;

    .form-group {
      margin-bottom: 16px;
      label {
        margin-bottom: 4px;
      }
      .form-control {
        display: block;
        padding: 8px;
      }
    }

    .error {
      margin: 4px 0;
      color: $warning;
      font-size: 0.9rem;
    }

    .btn {
      border-radius: 6px;
      font-size: 1rem;
      font-weight: 400;
      text-transform: inherit;
    }

    .btn-close-form {
      padding: 0 4px;
      background-color: $color-whatsapp-green-2;
      color: white;
      position: absolute;
      top: 10px;
      right: 10px;
    }

    .btn-submit {
      background-color: $color-whatsapp-green-3;
      width: 100%;
      margin-top: 10px;
      color: white;
    }
  }
  .btn-whatsapp {
    background-color: $color-whatsapp-green-3;
    box-shadow: 2px 2px 3px #999;
    padding: 10px;
    border-radius: 99999px;
    height: 70px;
    width: 70px;

    &.fade-in {
      transition: opacity 1s ease;
    }

    &.fade-out {
      opacity: 0;
      transition: opacity 1s ease;
    }

    svg {
      height: 50px;
      width: 50px;
      padding-left: 3px;
      padding-bottom: 3px;
      color: white;
    }
  }
}

@media screen and (max-width: 767px) {
  .whatsapp-button {
    position: fixed;
    bottom: 18px;
    right: 18px;
    z-index: 99;
    .btn-whatsapp {
      padding: 10px;
      height: 60px;
      width: 60px;
      svg {
        color: white;
        height: 40px;
        width: 40px;
      }
    }
  }
}
