.benefits {
  //background-color: red;

  h3 {
    text-align: center;
  }

  .card {
    text-align: center;
    margin-bottom: 30px;
    border: none;
    background-color: transparent;
    .card-img {
      // background-color: rgba(#000, 0.04);
      background-color: #fff;
      //border: 4px solid $secondary;
      box-shadow: 0px 0px 12px rgba(#000, 0.1);
      color: $secondary;
      border-radius: 50%;
      width: 212px;
      height: 212px;
      margin: auto;

      display: flex;
      align-items: center;
      justify-content: center;

      i {
        //background-color: red;
      }
    }
    .card-body {
      .card-title {
        font-size: 1.7rem;
        line-height: 2.2rem;
        letter-spacing: 0.04rem;
        font-weight: 400;
        color: $secondary;
        height: 80px;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .benefits {
    .card {
      text-align: center;
      margin-bottom: 30px;
      .card-img-top {
        width: 40%;
        margin: auto;
      }
      .card-body {
        height: auto;
        .card-title {
          font-size: 1.6rem;
          font-weight: 600;
        }
      }
    }
  }
}
