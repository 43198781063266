.features {
  background-color: rgba($gray-200, 0.4);
  padding: 120px 0;
  article {
    .content {
      padding: 10px 20px;
    }
    p {
      i {
        margin-right: 10px;
        color: $primary;
      }
      font-size: 1.5rem;
      margin-bottom: 20px;
    }
  }
}

@media screen and (max-width: 767px) {
  .features {
    padding: 80px 0;

    article {
      p {
        font-size: 1.4rem;
        margin-bottom: 30px;
      }
    }
  }
}
