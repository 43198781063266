.phrase {
  position: relative;
  background-color: #fff;
  h2,
  h4 {
    font-weight: 400;
    text-align: center;
  }
  h2 {
    text-transform: uppercase;
    margin-bottom: 30px;
    span {
      font-weight: 600;
      color: darken($primary, 4%);
    }
  }
  h4 {
    margin-bottom: 30px;
    span {
      text-decoration: underline;
    }
  }
  .icon {
    width: 100%;
    height: 70px;
    position: absolute;
    text-align: center;
    bottom: 0;

    i {
      color: $secondary;
    }
  }
}

@media screen and (max-width: 767px) {
  .phrase {
    position: relative;
    background-color: #fff;
    h2,
    h4 {
      font-weight: 400;
      text-align: center;
    }
    h2 {
      text-transform: uppercase;
      margin-bottom: 30px;
      // font-size: 1.4rem;
      span {
        font-weight: 600;
        color: darken($primary, 4%);
      }
    }
    h4 {
      margin-bottom: 30px;
      font-size: 1.4rem;
      span {
        text-decoration: underline;
      }
    }
    .icon {
      width: 100%;
      height: 70px;
      position: absolute;
      text-align: center;
      bottom: 0;

      i {
        color: $secondary;
      }
    }
  }
}
