.validation {
  background-color: rgba($gray-400, 0.4);
  padding: 120px 0;

  .img-fluid {
    border-radius: 8px;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) {
  .validation {
    padding: 80px 0;
    .img-fluid {
      margin-bottom: 30px;
    }
  }
}
